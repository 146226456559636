import {NgModule} from '@angular/core';
import {FeatherIconDirective} from '@shared/directives/feather-icons.directive';
import {RippleEffectDirective} from '@shared/directives/ripple-effect.directive';
import {InputToUppercaseDirective} from '@shared/directives/input-to-uppercase.directive';
import {InputToCapitalizeDirective} from '@shared/directives/input-to-capitalize.directive';
import {NgShowDirective} from '@shared/directives/ng-show.directive';

@NgModule({
  imports: [
    RippleEffectDirective,
    FeatherIconDirective,
    InputToUppercaseDirective,
    InputToCapitalizeDirective,
    NgShowDirective,
  ],
  exports: [
    RippleEffectDirective,
    FeatherIconDirective,
    InputToUppercaseDirective,
    InputToCapitalizeDirective,
    NgShowDirective,
  ]
})
export class CoreDirectivesModule {
}
