import {NgModule} from '@angular/core';
import {SafePipe} from '@shared/pipes/safe.pipe';
import {FilterPipe} from './filter.pipe';
import {LocalizedDatePipe} from './localized-date.pipe';
import {ErrorMessagePipe} from './error-message.pipe';
import {DateGeneratorPipe} from '@shared/pipes/date-generator.pipe';

@NgModule({
  imports: [
    FilterPipe,
    SafePipe,
    LocalizedDatePipe,
    ErrorMessagePipe,
    DateGeneratorPipe,
  ],
  exports: [
    FilterPipe,
    SafePipe,
    LocalizedDatePipe,
    ErrorMessagePipe,
    DateGeneratorPipe,
  ],
})
export class CorePipesModule {
}
